export default class Constants {
  static CQ_SITE = 'https://crystalquest.xyz/';

  static RYU_SITE = 'https://ryu.games/';

  static DISCORD_LINK = 'https://discord.gg/8XQYffUaeC';

  static TELEGRAM_LINK = 'https://t.me/CrystalQuest';

  static TWITTER_LINK = 'https://twitter.com/CrystalQuestXYZ';

  static WHITE_PAPER_LINK = 'https://whitepaper.crystalquest.xyz';
}
