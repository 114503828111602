import React from 'react';
import {
  Link,
} from 'react-router-dom';

export default function GenericNotFound() {
  const title = 'Oops!';
  const sub = "We couldn't find the page...";
  return (
    <div>
      <div className="not-found-container">
        <div id="svgCheckContainer" className="error-animation"></div>
        <h1 className="notfound-title">{title}</h1>
        <h2 className="notfound-sub">{sub}</h2>
        <Link className="btn nbtn notfound-btn" to="/">
          BACK
        </Link>
      </div>
    </div>
  );
}
