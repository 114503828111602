import React, { useState, useRef, useEffect } from 'react';
import Constants from '../../constants';

const WIDTH_THRESHOLD = 1110;

export default function Navbar(dimension) {
  const [isPopupShown, setIsPopupShown] = useState(false);
  const popupRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (
        isPopupShown
          && popupRef.current
          && !popupRef.current?.contains(event.target)
      ) {
        setIsPopupShown(false);
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  });

  return (
        <div className="navbar">
            <div className="left-side" style={{ visibility: dimension.width > WIDTH_THRESHOLD ? 'visible' : 'hidden' }}>
              <a target="_blank" href={Constants.DISCORD_LINK} rel="noreferrer" >
                <img src="images/discord.png" alt={'discord-link'} className="discord-icon"/>
              </a>
              <a target="_blank" href={Constants.TELEGRAM_LINK} rel="noreferrer">
                <img src="images/telegram.png" alt={'telegram-link'} className="telegram-icon"/>
              </a>
              <a target="_blank" href={Constants.TWITTER_LINK} rel="noreferrer">
                <img src="images/twitter.png" alt={'twitter-link'} className="twitter-icon"/>
              </a>
            </div>
            <div className="center">
              <a href={Constants.CQ_SITE} rel="noreferrer">
                <img src="images/logo.png" className='crystal-quest-logo' alt="crystal-quest-logo"/>
              </a>
            </div>
            { dimension.width > WIDTH_THRESHOLD ? (
                <div className="right-side" style={{ visibility: dimension.width > WIDTH_THRESHOLD ? 'visible' : 'hidden' }}>

                    <a href={Constants.WHITE_PAPER_LINK} className="link" target="_blank" rel="noopener noreferrer">
                      <div className="menu-img"/>
                    </a>

                    <a className="link" href={Constants.RYU_SITE} target="_blank" rel="noopener noreferrer" >
                      <div className="menu-img-ryu"/>
                    </a>

                </div>
            ) : (
                <div className='right-side' >
                  <a href={Constants.WHITE_PAPER_LINK} className="link" target="_blank" rel="noopener noreferrer">
                    <div className="menu-img"/>
                  </a>

                  <a className="link" href={Constants.RYU_SITE} target="_blank" rel="noopener noreferrer" >
                    <div className="menu-img-ryu"/>
                  </a>
                </div>
            )}
        </div>
  );
}
