import React, { useState, useEffect } from 'react';
import Navbar from './components/NavBar';
import Constants from '../constants';

export const WIDTH_THRESHOLD = 1110;

export default function HomePage() {
  const [width, setWidth] = useState(null);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWidth();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateWidth, {
      capture: false,
      passive: true,
    });

    return () => window.removeEventListener('resize', updateWidth);
  });

  return (
    <div>
      <div className="App">
          {<Navbar width={width} />}
        <div className="App-intro">
            <div className="home-image-container">
                {/* eslint-disable-next-line no-nested-ternary */}
            </div>
        </div>
        {width < WIDTH_THRESHOLD && (
            <div className="left-side">
              <div className="icons-container">
                <a target="_blank" href={ Constants.DISCORD_LINK } rel="noreferrer" >
                  <img src="images/discord.png" alt={'discord-link'} className="discord-icon"/>
                </a>
                <a target="_blank" href={Constants.TELEGRAM_LINK} rel="noreferrer">
                  <img src="images/telegram.png" alt={'telegram-link'} className="telegram-icon"/>
                </a>
                <a target="_blank" href={Constants.TWITTER_LINK} rel="noreferrer">
                  <img src="images/twitter.png" alt={'twitter-link'} className="twitter-icon"/>
                </a>
              </div>

            </div>
        )}
      </div>
    </div>
  );
}
