import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import './css/style.css';
import './css/homepage.css';

import HomePage from './homepage/index';
import GenericNotFound from './notfound/index';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<GenericNotFound />} />
        <Route exact path="/" element={<HomePage />}/>
      </Routes>
    </Router>
  );
}
